import React from "react"
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import Layout from "../components/App/Layout"
import CareerForm from "../components/Career/CareerForm"
import SEO from "../components/App/SEO"
import { graphql } from "gatsby"
import calendarImg from "../assets/images/career/calendar-check.svg"
import brifcaseImg from "../assets/images/career/brifcase.svg"

export default function CareerTemplate(props) {

  const post = props.pageContext
  let postHTML = post.html

  return (
    <Layout env={props.data.site.siteMetadata.env}>
          <Navbar />
          <SEO
            postTitle={post.frontmatter.metaTitle + " | BlueMail App"}
            postDescription={post.frontmatter.metaDescription}
            postImage={post.frontmatter.ogImage ? post.frontmatter.ogImage : post.frontmatter.image}
            postURL={`career/${post.frontmatter.path}`}
          />
         {post.frontmatter.publishToProd && <div className="container-fluid single-career pl-0 pr-0">
            <div className="career-header">
              <div className="container">
                <div className="row">
                  <div className="col">
                    <p className="career-logo">BlueMail</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col mb-3">
                    <p className="careers">Careers /</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <p className="jobTitle">{post.frontmatter.jobTitle}</p>
                  </div>
                </div>
              </div> 
            </div>    
            <div className="container career-post">
              <div className="row">
                <div className="col">  
                  <div className="row">
                    <div className="col">
                      <h1 style={{fontWeight: 600}}>POSITION DESCRIPTION</h1>
                    </div>    
                  </div>    
                  <div className="row">
                    <div className="col d-flex p-2 pb-3">
                      {/* <p className="post-details">{post.frontmatter.posted}</p> */}
                      <p className="post-details">{post.frontmatter.jobType}</p>
                    </div>
                  </div>  
                  <br />      
                  <div className="row">
                    <div
                      id="post-html"
                      className="col"
                      dangerouslySetInnerHTML={{ __html: postHTML }}
                    />
                  </div>
                </div>
              </div>
            <CareerForm />
            </div>                  
          </div>  
        }
        <Footer />
    </Layout>
  )
}

export const query = graphql`
  query CareerTemplatePageQuery {
    site {
      siteMetadata {
        title
        env
      }
    }
  }
`