import React, {useRef, useState} from 'react'
import emailjs from "@emailjs/browser"

const Contact = () => {

    const jobTitle = typeof window !== 'undefined' ? window.location.href.split('/').pop() : '';    
    const [status, setStatus] = useState(undefined);
    const form = useRef()
    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('career_bluemail_q15h7S', 'template_scyhkl4', form.current, 'OnePou9Y2x1TroFKI')
          .then((result) => {
              console.log(result);
              setStatus({ type: 'success' });
          }, (error) => {
              console.log(error.text);
              setStatus({ type: 'error', error });
          });
          e.target.reset();
      };

  return (         
    <div className='row'>
        <div className='col'>
            <hr style={{marginTop: "5%"}}/>
            <div className='row d-flex justify-content-center align-items-center'>
                <div className='col text-center' style={{margin: "20px auto 30px"}}>
                    <h6 style={{color: "#1F6BF1"}}>Interested in this position? Please <br /> 
                    complete the fields below and we will get in touch with you</h6>
                </div>
            </div>
            <div className='row d-flex justify-content-center align-items-center'>                
                <div className='col-xl-7 col-md-7 col-xs-12'>   
                    <div className='row'>                            
                        <div className='col'>                                  
                            <form ref={form} onSubmit={sendEmail}>
                                <div className='row' style={{border: "1px solid #1F6BF1", padding: "30px 20px", borderRadius: "5px"}}>
                                    <div className='col'>
                                        <div class="form-group">
                                            <label for="firstName">First name*</label>
                                            <input type="text" class="form-control" name="first_name" required placeholder='John' />
                                        </div>
                                        <div class="form-group">
                                            <label for="lastName">Last name*</label>
                                            <input type="text" class="form-control" name="last_name" required placeholder='Smith'/>
                                        </div>
                                        <div class="form-group">
                                            <label for="email">Email*</label>
                                            <input type="email" class="form-control" name="user_email" required placeholder='example@example.com' />
                                        </div>
                                        <div class="form-group">
                                            <label for="phoneNumber">Phone number*</label>
                                            <input type="number" class="form-control" name="user_phone_number" required placeholder='+44 0987654321'/>
                                        </div>
                                        <div class="form-group">
                                            <label for="resume">Upload your resume</label>
                                            <input type="file" class="form-control-file" />
                                        </div>
                                        <div class="form-group">
                                            <input type="hidden" readonly="" name="posted_job" value={jobTitle}/>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col' style={{marginTop: "40px"}}>
                                        <button type="submit" class="default-btn d-block m-auto" style={{padding: "5px 35px"}}>Submit</button>
                                    </div>
                                </div>                                
                            </form>                                        
                            {status?.type === 'success' && <p style={{lineHeight: "20px", fontSize: "14px", color: "#1F6BF1", fontWeight: "bold"}} className='text-center normal color-default mt-3'>
                                Candidature for <span style={{textTransform: "capitalize"}}>{jobTitle}</span> was send successfully! <br /> Email confirmation has been sent to your address.</p>}
                            {status?.type === 'error' && (<p style={{lineHeight: "20px", fontSize: "14px"}} className='text-center normal color-default mt-3'>
                                Your application has not been sent! <br /> 
                                Please verify the information and try again. <br /> 
                                If Error persists please send your application to <a href="careers@blixhq.com"></a>careers@blixhq.com </p>)}
                        </div>                            
                    </div>
                </div>
            </div>
        </div>  
    </div>          
  )
}

export default Contact
